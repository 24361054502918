import React from "react";
import '../styles/Preparation.scss'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Preparation = () => {
    return (
        <section>
            <div className="logoContainer"><StaticImage src="../images/logo_vertical.png" alt="シェアスペースCo.Toraのロゴマーク" width={200}/></div>
            <div className="textContainer">
                <h1>Co.Toraは現在準備中です。</h1>
                <p>
                    シェアスペースとしてたくさんの方に利用していただけるよう、準備を進めております。
                    ご利用をお待ちいただいている皆様には、大変ご迷惑をおかけいたしますが、今しばらくお待ちいただけますようお願い申し上げます。
                </p>
            </div>
        </section>
    )
}

export default Preparation;