import React from "react";
import "../styles/globals.scss";
import { Helmet } from "react-helmet";

const Layout = ({children}) => {
  return (
      <div className="application">
        <Helmet>
          <title>シェアスペースCo.Tora-岩槻駅から徒歩5分-</title>
          <meta name="description" content="現在準備中のシェアスペース。オープンまでお待ちください。"/>
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap" rel="stylesheet" />
          <link rel="apple-touch-icon" type="image/png" href="../images/apple-touch-icon-180x180.png" />
          <link rel="icon" type="image/png" href="../images/icon-192x192.png" />
        </Helmet>
        <div>
          {children}
        </div>
      </div>
  )
}

export default Layout;