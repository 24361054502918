import * as React from "react";
import Layout from "../components/Layout";
import Preparation from "../components/Preparation";


// markup
const IndexPage = () => {
  return (
      <Layout>
        <Preparation />
      </Layout>
  );
}

export default IndexPage
